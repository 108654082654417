import React from "react"
import SEO from "../components/seo"
import "../styles/index.css"
import "../styles/utilities.css"
import "../styles/contact.css"
import "../styles/photo-video.css"
import ResponsiveEmbed from "react-responsive-embed"
import ImageGallery from "react-image-gallery"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import BoyGolden1 from "../images/Gallery/Boy-Golden-Church-of-Better-Daze1.jpg"
import BoyGolden2 from "../images/Gallery/Boy-Golden-Church-of-Better-Daze2.jpg"
import BoyGolden3 from "../images/Gallery/Boy-Golden-Church-of-Better-Daze3.jpg"
import BoyGolden4 from "../images/Gallery/Boy-Golden-Church-of-Better-Daze4.jpg"
import BoyGolden5 from "../images/Gallery/Boy-Golden-Church-of-Better-Daze5.jpg"
import BoyGolden6 from "../images/Gallery/Boy-Golden-Church-of-Better-Daze6.jpg"
import BoyGolden7 from "../images/Gallery/Boy-Golden-Church-of-Better-Daze7.jpg"
import BoyGolden8 from "../images/Gallery/Boy-Golden-Church-of-Better-Daze8.jpg"

const Media = ({ data, location }) => {
  const title = "Pics and Vids"
  const description =
    "See our church photo and video gallery. A true showcase of our ever so special minister Boy Golden. "

  const images = [
    {
      original: BoyGolden1,
      originalAlt: "Boy Golden Portrait 1",
    },
    {
      original: BoyGolden2,
      originalAlt: "Boy Golden Portrait 2",
    },
    {
      original: BoyGolden3,
      originalAlt: "Boy Golden Portrait 3",
    },

    {
      original: BoyGolden4,
      originalAlt: "Boy Golden Portrait 4",
    },

    {
      original: BoyGolden5,
      originalAlt: "Boy Golden Portrait 5",
    },
    {
      original: BoyGolden6,
      originalAlt: "Boy Golden Portrait 6",
    },
    {
      original: BoyGolden7,
      originalAlt: "Boy Golden Portrait 7",
    },

    {
      original: BoyGolden8,
      originalAlt: "Boy Golden Portrait 8",
    },
  ]

  return (
    <>
      <SEO title={title} description={description} />

      <div className="page-container pics">
        <Navbar />
        <div className="content-container ">
          <h1 className="txt-highlight center-text txt-shadow2">Church Vids</h1>
          <br />
          <div className="tacky-border4 video-padding">
            <ResponsiveEmbed src="https://www.youtube.com/embed/oVlBi_eHWgw" />
          </div>
          <div className="tacky-border1 video-padding">
            <ResponsiveEmbed src="https://www.youtube.com/embed/a-dB3V4yyJ8" />
          </div>
          <div className="tacky-border3 video-padding">
            <ResponsiveEmbed src="https://www.youtube.com/embed/XwUVn08KDmg" />
          </div>
          <div className="tacky-border2 video-padding">
            <ResponsiveEmbed src="https://www.youtube.com/embed/ceX1iXNHW_w" />
          </div>
          <br />
          <h2 className="txt-highlight center-text txt-shadow2">Church Pics</h2>
          <br />

          <ImageGallery
            items={images}
            showThumbnails={false}
            showPlayButton={false}
            useBrowserFullscreen={false}
            showFullscreenButton={false}
            lazyLoading={true}
          />
          <br />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Media
